.App {
  text-align: center;
  /* background: #ffd467; */
  max-width: 500px;
  margin: 0 auto;
  padding: 0 25px;
}

.logo {
  width: 200px;
  margin: 40px 0;
}

pre {
  text-align: left;
  font-family: "ApfelReg";
  white-space: break-spaces;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.format-not-supported {
  background-color: #eee;
  padding: 20px;
  margin-top: 20px;
}

.format-not-supported p {
  margin: 0;
}

.App-header {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
}

.App-header .inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
}

.App-link {
  color: #61dafb;
}
body.is-sticky {
  padding-top: 124px;
}

/* --- AUDIO PLAYER --- */
.audio-player {
  max-width: 500px;
  /* border-radius: 20px; */
  padding: 10px;
  /* box-shadow: 0 18px 18px rgba(0, 0, 0, 0.2); */
  margin: auto;
  color: var(--white);
}

.artwork {
  border-radius: 10px;
  /* display: block; */
  /* margin: auto; */
  /* height: 200px;
  width: 200px; */
}

.track-info {
  text-align: center;
  z-index: 1;
  position: relative;
}

.title {
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 20px;
}

.artist {
  font-weight: 300;
  margin-top: 0;
  font-size: 15px;
}
.artist span {
  font-weight: bold;
}
/* DURATION */
.duration {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.duration p {
  color: red;
}
/* CONTROLS */
.audio-controls {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px auto 15px;
}

.audio-controls .prev svg,
.audio-controls .next svg {
  width: 35px;
  height: 35px;
}

.audio-controls .play svg,
.audio-controls .pause svg {
  height: 40px;
  width: 40px;
}
.audio-controls .play,
.audio-controls .pause {
  height: 50px;
  width: 50px;
  border-radius: 30px;
  border: none;
  background-color: #ef544f;
}
.audio-controls .play {
  background-color: #ff7e79;
}
.audio-controls .play::before {
  content: "\f04b";
  font-family: "FASol";
  color: #fff;
}
.audio-controls .pause::before {
  content: "\f04c";
  font-family: "FASol";
  color: #fff;
}
.audio-controls path {
  fill: var(--white);
}
input[type="range"] {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #3b7677;
  transition: background 0.2s ease;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  /* border: 1px solid #00001e; */
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: #ef544f;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -1px;
}

.color-backdrop.playing {
  animation: colorChange 20s alternate infinite;
}
@keyframes colorChange {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}
/* --- END AUDIOPLAYER --- */

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 10px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: absolute;
}
header.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 50px);
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 80px;
  padding: 0 25px;
}

h1 {
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left;
  font-family: "ApfelBold";
}
h2 {
  font-size: 18px;
  margin-top: 0;
  font-family: "ApfelBold";
}
h3 {
  font-size: 16px;
  font-family: "ApfelReg";
  font-weight: normal;
  margin-bottom: 20px;
}
p {
  text-align: left;
}
.contentImg {
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
}
.cta-wrp {
  background: #fff;
  padding: 40px 30px;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 60px;
}
.link-wrp {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.link-wrp a,
.modal-btn {
  background: #ff7e79;
  /* padding: 15px 25px; */
  border-radius: 8px;
  display: flex;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  font-family: "ApfelBold";
}
.link-wrp a img {
  margin: 0;
  /* width: 160px */
  /* height: 50px;
  width: 100%; */
}
.modal-btn {
  padding: 10px 18px;
  border: none;
  cursor: pointer;
}
.link-wrp a:hover,
.modal-btn:hover {
  background: #ef544f;
}
.modal {
  position: fixed;
  /* width: 100vw;
  height: 100vh; */
  /* top: 0;
  left: 0; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal .cta-wrp {
  margin: 0 20px;
  max-width: 600px;
  min-width: 260px;
  margin: 0 20px;
}

/* -- COACH -- */
.coach-wrp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.expert-area-wrp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}
.expert-area {
  /* border: 1px solid #f47d79; */
  /* display: block; */
  /* border-radius: 9px; */
  /* padding: 10px 0px; */
  padding-left: 15px;
  margin-bottom: 5px;
  margin-right: 10px;
  position: relative;
}
.expert-area::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #3b7677;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 3.5px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media all and (max-width: 520px) {
  .logo {
    max-width: 120px;
  }
  body.is-sticky {
    padding-top: 105px;
  }
}
