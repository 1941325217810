@font-face {
  font-family: "ApfelReg";
  src: local("ApfelReg"),
    url("./fonts/ApfelGrotezk-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "ApfelBold";
  src: local("ApfelBold"),
    url("./fonts/ApfelGrotezk-Fett.ttf") format("truetype");
}
@font-face {
  font-family: "FAReg";
  src: local("FAReg"),
    url("./fonts/FontAwesome5Pro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "FASol";
  src: local("FASol"),
    url("./fonts/FontAwesome5Pro-Solid.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "ApfelReg", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff7e4;
}

/* body.modal-open {
  overflow-y: hidden;
  overflow: hidden;
}
#root.modal-open {
  overflow-y: hidden;
  overflow: hidden;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
